export enum Color {
  background = '#ffffff',
  backgroundGrey = '#f7f8f9',
  backgroundRed = '#a30029',
  ticketRed = '#ff6247',
  ticketBlue = '#0c8af5',
  ticketGrey = '#c5c8ce',
  ticketGreen = '#3dae2b',
  secondaryBackground = '#f5f6f8',
  primary1 = '#000000',
  primary2 = '#2D2D2D',
  secondary1 = '#777777',
  secondary2 = '#C5C8CE',
  lightSecondary2 = '#eeeeee',
  important = '#d70137',
  secondaryImportant = '#ff6247',
  alert = '#e04403',
  alert2 = '#ff6247',
  dark = '#0c27a5',
  kakao = '#f8dd01',
  kakaoBrown = '#391f1f',
  fontColor = primary2,
  blue = '#00b8ff',
  lightBlue = '#00b8ff',
  darkBlue = '#0c27a5',
  purple = '#693dcf',
  buttonPrimary = '#0fd6f7',
  green = '#0eca2d',
  cyan = '#2ac1db',
  white = '#ffffff',
  playBlue = '#72ACC6',
}

export const BORDER_RADIUS = 5;

export enum ModalStatus {
  Success = 'success',
  Error = 'error',
  Confirm = 'confirm',
  Normal = 'normal',
}

export enum PaymentType {
  CreditCard = 'credit_card',
  Cash = 'cash',
  HandWritten = 'hand_written',
  KakaoPay = 'kakaopay',
  PayLater = 'pay_later',
}

export const PaymentTypemap: { [index: string]: string } = {
  [PaymentType.CreditCard]: '신용/체크카드',
  [PaymentType.Cash]: '현금/상품권',
  [PaymentType.HandWritten]: '신용카드 수기',
  [PaymentType.KakaoPay]: '카카오페이',
  [PaymentType.PayLater]: '나중에결제',
};

export enum OrderStatus {
  WaitForPayment = 'waitforpayment',
  Paid = 'paid',
  Cancelled = 'cancelled',
}

export const OrderStatusMap: { [index: string]: string } = {
  [OrderStatus.WaitForPayment]: '결제 대기',
  [OrderStatus.Paid]: '결제 완료',
  [OrderStatus.Cancelled]: '환불',
};

export enum UserCouponStatus {
  Expired = 'expired',
  Paid = 'paid',
  Used = 'used',
  WaitForPayment = 'waitforpayment',
  Cancelled = 'cancelled',
}

export const UserCouponStatusMap: { [index: string]: string } = {
  [UserCouponStatus.WaitForPayment]: '결제 대기중',
  [UserCouponStatus.Paid]: '사용 전',
  [UserCouponStatus.Used]: '사용 중',
  [UserCouponStatus.Expired]: '유효기간 만료',
  [UserCouponStatus.Cancelled]: '취소/환불',
};

export const zoneMap: { [index: string]: string } = {
  basic: 'Basic Monster',
  exciting: 'Exciting Monster',
  adventure: 'Adventure Monster',
  digital: 'Digital Monster',
};

export const CouponTagMap: { [index: string]: string } = {
  online: '온라인결제전용',
  offline: '오프라인전용',
};

export const CouponStatusMap: { [index: string]: string } = {
  [UserCouponStatus.WaitForPayment]: '결제 대기중',
  [UserCouponStatus.Paid]: '사용 전',
  [UserCouponStatus.Used]: '사용완료',
  [UserCouponStatus.Expired]: '기간만료',
  [UserCouponStatus.Cancelled]: '취소/환불',
};

export enum NoticeType {
  Expired = 'expired',
  Used = 'used',
}

export const NoticeTypeMap: { [index: string]: string } = {
  [NoticeType.Expired]: '기간만료',
  [NoticeType.Used]: '사용완료',
};

export enum OrderSaledAt {
  TicketBox = 'ticket_box',
  Online = 'online',
  Kiosk = 'kiosk',
  B2b = 'b2b',
}

export enum WebViewEventType {
  appleLogin = 'appleLogin',
  NFCRead = 'NFCRead',
  localPush = 'localPush',
  registerMission = 'registerMission',
  saveVideo = 'saveVideo',
  getNotiPermission = 'getNotiPermission',
  getPhotoPermission = 'getPhotoPermission',
  uploadImage = 'uploadImage',
  linkToSetting = 'linkToSetting',
  linkToNfcSetting = 'linkToNfcSetting',
  linkToUrl = 'linkToUrl',
  userData = 'userData',
}

export enum PermissionStatus {
  unavailable = 'unabailable',
  denied = 'denied',
  limited = 'limited',
  granted = 'granted',
  blocked = 'blocked',
}

export enum Permission {
  gallery = 'gallery',
  notification = 'notification',
}

export const PermissionMap: { [index: string]: string } = {
  gallery: '영상',
  notification: '알림',
};

export enum CouponStatus {
  Enabled = 'enabled',
  Disabled = 'disabled',
}

export enum CouponLifeTimeUnit {
  MilliSecond = 'millisecond',
  Second = 'second',
  Minute = 'minute',
  Hour = 'hour',
  Day = 'day',
  Month = 'month',
  Year = 'year',
}

export interface DownloadFile {
  fileName: string;
  percentage: number;
}

export enum ExposeTag {
  Event = 'event',
  Low = 'low',
  High = 'high',
}
