import { DefaultSeoProps } from 'next-seo';

const DEFAULT_SEO: DefaultSeoProps = {
  defaultTitle: '스포츠몬스터 대전 신세계 아트앤사이언스점',
  title: '스포츠몬스터 대전 신세계 아트앤사이언스점',
  description: '베이직, 익사이팅, 어드벤처, 디지털 스포츠 등 다양한 스포츠를 즐길 수 있는 스포츠 놀이 문화 공간입니다',
  canonical: 'https://daejeon.sportsmonster.co.kr/',
  openGraph: {
    title: '스포츠몬스터 대전 신세계 아트앤사이언스점',
    site_name: '스포츠몬스터 대전 신세계 아트앤사이언스점',
    url: 'https://daejeon.sportsmonster.co.kr/',
    type: 'website',
    locale: 'ko_KR',
    images: [
      {
        url: 'https://cdn.swingby.us/smob-kakao.png',
        width: 800,
        height: 400,
        alt: '스포츠몬스터 로고',
      },
    ],
  },
};

export default DEFAULT_SEO;
