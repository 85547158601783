import { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import { useRouter } from 'next/router';
import { DefaultSeo } from 'next-seo';
import React, { useEffect } from 'react';
import { ApolloProvider } from '@apollo/client';
import { RecoilRoot } from 'recoil';
import styled, { createGlobalStyle } from 'styled-components';
import AdapterDayJs from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import koLocale from 'dayjs/locale/ko';
import { Color, WebViewEventType } from '../constants';
import '../styles/globals.css';
import { client } from '../apollo';
import { QUERY_LOGGED_IN, QUERY_USER } from '../apollo/queries';
import * as gtag from '../lib/gtag';
import * as fbq from '../lib/fbpixel';
import * as gtm from '../lib/gtm';
import PcWrapper from '../components/Footer/PcWrapper';
import { userVar } from '../apollo/cache';
import useWebView from '../hooks/useWebView';
import DEFAULT_SEO from '../next-seo.config';

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const user = userVar();
  const { postMessage } = useWebView();

  client.query({ query: QUERY_LOGGED_IN });
  client.query({ query: QUERY_USER });

  useEffect(() => {
    fbq.pageview();
    const handleRouteChange = (url: URL) => {
      gtm.pageview(url);
      gtag.pageview(url);
      fbq.pageview();
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    router.events.on('hashChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
      router.events.off('hashChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    const handleResize = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    window.addEventListener('resize', handleResize);
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (user) postMessage({ type: WebViewEventType.userData, data: { message: user.uid } });
  }, [user]);

  useEffect(() => storePathValues, [router.asPath]);
  useEffect(() => {
    if (user) {
      window.gtag('config', gtag.GA_ID, { user_id: user.uid });
      //@ts-ignore
      window.dataLayer.push({ user_id: user.uid, crm_id: user.uid });
    }
  }, [user]);

  function storePathValues() {
    const storage = globalThis?.sessionStorage;
    if (!storage) return;

    const prevPath = storage.getItem('currentPath');
    storage.setItem('prevPath', prevPath!);
    storage.setItem('currentPath', globalThis.location.pathname);
  }

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, height=device-height, initial-scale=1.0, user-scalable=no, shrink-to-fit=yes"
        />
      </Head>
      <DefaultSeo {...DEFAULT_SEO} />
      <Script
        src="https://developers.kakao.com/sdk/js/kakao.js"
        onLoad={() => window.Kakao.init('b04eab256117e70aa6cbdda67edceba7')}
        onError={(e) => console.error(e)}
      />
      <Script src="https://cdn.bootpay.co.kr/js/bootpay-3.3.1.min.js" onError={(e) => console.error(e)} />
      {/* Google Tag Manager - Global base code */}
      <Script
        id="gtag-base"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${gtm.GTM_ID}');
          `,
        }}
      />
      {/* Global Site Tag (gtag.js) - Google Analytics */}
      <Script strategy="afterInteractive" src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_ID}`} />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gtag.GA_ID}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
      {/* Global Site Code Pixel - Facebook Pixel */}
      {process.env.NEXT_PUBLIC_PRODUCTION === 'true' && (
        <Script
          id="facebook-pixel"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', ${fbq.FB_PIXEL_ID});
          `,
          }}
        />
      )}
      <ApolloProvider client={client}>
        <RecoilRoot>
          <LocalizationProvider dateAdapter={AdapterDayJs} locale={koLocale}>
            <Wrapper style={{ backgroundColor: router.pathname === '/play/history' ? Color.playBlue : '' }}>
              <Component {...pageProps} />
            </Wrapper>
            <PcWrapper />
            <GlobalStyle />
          </LocalizationProvider>
        </RecoilRoot>
      </ApolloProvider>
    </>
  );
}

const Wrapper = styled.div({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
});

const GlobalStyle = createGlobalStyle`
  * {    
    touch-action: manipulation;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
  }

  #__next {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: calc(var(--vh, 1vh) * 100);
    max-width: 640px;
    min-width: 360px;    
    background-color: ${Color.backgroundGrey} !important;
    position: relative;    
    z-index: 1;
  }

  .modal-dialog bottom {
    top: calc(100% - 300px);
  }
  
  .modal-content {
    border-radius: 10px !important;
  }

  .nav-item.show .nav-link, .nav-tabs{
    color: ${Color.primary2} !important;
  }
`;
