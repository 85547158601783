import { ApolloClient, createHttpLink, InMemoryCache, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createNetworkStatusNotifier } from 'react-apollo-network-status';
import { isLoggedInVar, userVar } from './cache';
import { QUERY_ME } from './queries';

const httpLink = createHttpLink({
  uri: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT,
});

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  let timer: any;
  const token = globalThis?.localStorage?.getItem('token');

  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      switch (err.extensions!.code) {
        case 'UNAUTHENTICATED':
          if (token) {
            localStorage.removeItem('token');
            timer = setTimeout(() => {
              alert('새 기기로 로그인 했거나, 너무 오래된 로그인으로 인해 로그아웃 되었습니다. 다시 로그인해주세요.');
              location.href = '/';
            }, 100);
            return forward(operation);
          }
          return forward(operation);
      }
    }
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    if (typeof window !== 'undefined') {
      timer = setTimeout(() => {
        alert('네트워크 에러가 발생헀습니다. 잠시 후 다시 시도해주세요.');
      }, 100);
    }
  }
});

const authLink = setContext((_, { headers }) => {
  const token = globalThis?.localStorage?.getItem('token');
  return {
    headers: {
      ...headers,
      token: token ? token : '',
    },
  };
});

export const { link: networkStatusLink, useApolloNetworkStatus } = createNetworkStatusNotifier();

export const client = new ApolloClient({
  link: from([errorLink, authLink, networkStatusLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          isLoggedIn: {
            read() {
              return isLoggedInVar();
            },
          },
          user: {
            read(_, { storage }) {
              const token = globalThis?.localStorage?.getItem('token');
              if (!token) return;
              if (!storage.var) {
                storage.var = userVar;
                client.query({ query: QUERY_ME }).then(({ data }) => storage.var(data.result));
              }
              return storage.var();
            },
          },
        },
      },
    },
  }),
});
