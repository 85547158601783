import { useRouter } from 'next/router';
import Image from 'next/image';
import { memo } from 'react';
import styled from 'styled-components';
import bi from '../../public/pc-branch-bi.png';
import launching from '../../public/pc-promo-launching.png';

const PcWrapper = () => {
  const router = useRouter();
  return (
    <div className="pc-wrapper">
      <div className="pc-branch-bi">
        <Image unoptimized src={bi} width={300} height={61} alt="Sports Monster BI" onClick={() => router.push('/')} />
      </div>
      <div className="pc-promo">
        <Image unoptimized src={launching} width={393} height={125} alt="launching" />
      </div>
      <div className="pc-branch-info">
        <strong>스포츠몬스터 대전신세계 아트앤사이언스점</strong>
        <br />
        대전광역시 유성구 엑스포로1 , 6층 & 7층
        <br />
        <br />
        <Term onClick={() => router.push('/terms/service')}>서비스 이용약관</Term> &nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
        <Term onClick={() => router.push('/terms/privacy')}>개인정보처리방침</Term> &nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
        <Term onClick={() => router.push('/terms/park')}>파크 이용약관</Term>
        <br />
        <br />
        상호: 위피크(주) 스포츠몬스터 대전신세계
        <br />
        주소: 대전광역시 유성구 엑스포로1 , 6층 & 7층
        <br />
        대표이사: 김정준 | 사업자등록번호: 214-87-97600
        <br />
        통신판매업신고: 제2016-경기하남-0426호 | 특허출원번호: 10-2017-0115016
        <br />
        고객센터 및 단체문의: 1668-4832(유료)
        <br />
        상담시간: 평일 오전 9시~ 오후 6시(점심 12-1시 제외)
        <br />
        이메일주소: sportsmonster@wepeak.co.kr
      </div>
    </div>
  );
};

export default memo(PcWrapper);

const Term = styled.span`
  cursor: pointer;
  &:hover {
    color: #fff;
  }
`;
